'use client';
import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import {
  ChartsTooltipCell,
  ChartsTooltipPaper,
  ChartsTooltipTable,
  ChartsTooltipMark,
  ChartsTooltipRow
} from '@mui/x-charts/ChartsTooltip/ChartsTooltipTable';
import type { ChartsAxisContentProps } from '@mui/x-charts/ChartsTooltip/ChartsAxisTooltipContent';
import { utcFormatter } from '@mui/x-charts/ChartsTooltip/utils';
import { getLabel } from '@mui/x-charts/internals/getLabel';
import { isCartesianSeries } from '@mui/x-charts/internals/isCartesian';
import { ScatterValueType } from '@mui/x-charts';
import { ChartSeriesDefaultized, ChartSeriesType } from '@mui/x-charts/models/seriesType/config';

type ChartSeriesDefaultizedWithColorGetter = ChartSeriesDefaultized<ChartSeriesType> & {
  getColor: (dataIndex: number) => string;
};

function calculateChange(data: number[] | ScatterValueType[], indexOfValue: number) {

  if (Array.isArray(data) && typeof data[0] === 'number') {
    const prevQuarter = data[indexOfValue - 1];
    const prevYear = data[indexOfValue - 4];
    // @ts-ignore
    const qoq = Math.round(((data[indexOfValue] - prevQuarter) / Math.abs(prevQuarter)) * 1000) / 10;
    // @ts-ignore
    const yoy = Math.round(((data[indexOfValue] - prevYear) / Math.abs(prevYear)) * 1000) / 10;

    return ' QoQ: ' + (indexOfValue == 0 ? 'n/a' : qoq + '%') +
      ', YoY: ' + (indexOfValue < 3 ? 'n/a' : yoy + '%');
  } else return '';
}

function sumAt(series: ChartSeriesDefaultizedWithColorGetter[], dataIndex: number) {
  return series.reduce((accumulator, item) => {
    if (isCartesianSeries(item) && typeof item.data[dataIndex] === 'number') {
      return accumulator + item.data[dataIndex];
    }
    return accumulator;
  }, 0);
}

function isStack(series: ChartSeriesDefaultizedWithColorGetter[]): boolean {
  return series.every(item => 'stack' in item);
}

function calculateTotalChange(total: number, totalPrevQuarter: number) {
  return Math.round(((total - totalPrevQuarter) / Math.abs(totalPrevQuarter)) * 1000) / 10;
}

function TotalChartsAxisTooltipContent(props: ChartsAxisContentProps) {
  const { series, axis, dataIndex, axisValue, sx, classes } = props;

  if (dataIndex == null) {
    return null;
  }

  const axisFormatter =
    axis.valueFormatter ??
    ((v: string | number | Date) =>
      axis.scaleType === 'utc' ? utcFormatter(v) : v.toLocaleString());

  const totalThisQuarter = sumAt(series, dataIndex);
  const totalPrevQuarter = sumAt(series, dataIndex - 1);
  const totalPrevYear = sumAt(series, dataIndex - 4);
  const totalChangeQoq = calculateTotalChange(totalThisQuarter, totalPrevQuarter);
  const totalChangeYoy = calculateTotalChange(totalThisQuarter, totalPrevYear);

  const showTotal = isStack(series);

  // @ts-ignore
  const formattedTotalThisQuarter = series.at(0).valueFormatter(totalThisQuarter);

  return (
    <ChartsTooltipPaper
      sx={{
        ...sx, pointerEvents: 'none' // Prevents tooltip from interfering with input
      }}
      className={classes.paper}>

      <ChartsTooltipTable className={classes.table}>

        {axisValue != null && !axis.hideTooltip && (
          <thead>
          <ChartsTooltipRow>
            <ChartsTooltipCell colSpan={4}>
              <Typography>{'20' + axisFormatter(axisValue, { location: 'tooltip' })}</Typography>
            </ChartsTooltipCell>
          </ChartsTooltipRow>
          </thead>
        )}

        <tbody>
        {series.filter(isCartesianSeries).map(({ id, label, valueFormatter, data, getColor }) => {
          // @ts-ignore
          const formattedValue = valueFormatter(data[dataIndex] ?? null, { dataIndex });
          if (formattedValue == null) {
            return null;
          }
          const formattedLabel = getLabel(label, 'tooltip');
          const color = getColor(dataIndex);
          return (
            <ChartsTooltipRow key={id} className={classes.row}>
              <ChartsTooltipCell className={clsx(classes.markCell, classes.cell)}>
                {color && <ChartsTooltipMark color={color} className={classes.mark} />}
              </ChartsTooltipCell>
              <ChartsTooltipCell className={clsx(classes.labelCell, classes.cell)}>
                {formattedLabel ? <Typography>{formattedLabel}</Typography> : null}
              </ChartsTooltipCell>
              <ChartsTooltipCell className={clsx(classes.valueCell, classes.cell)} align={'right'}>
                <Typography>{formattedValue}</Typography>
              </ChartsTooltipCell>
              <ChartsTooltipCell className={clsx(classes.valueCell, classes.cell)}>
                <Typography>{
                  calculateChange(data, dataIndex)
                }</Typography>
              </ChartsTooltipCell>
            </ChartsTooltipRow>
          );
        })}
        </tbody>

        {showTotal &&
          <>
            <thead>
            <ChartsTooltipCell colSpan={4} />
            </thead>

            <ChartsTooltipRow className={classes.row}>
              <ChartsTooltipCell className={clsx(classes.markCell, classes.cell)} />
              <ChartsTooltipCell className={clsx(classes.labelCell, classes.cell)}>
                <Typography>Total</Typography>
              </ChartsTooltipCell>
              <ChartsTooltipCell className={clsx(classes.valueCell, classes.cell)}>
                <Typography>{formattedTotalThisQuarter}</Typography>
              </ChartsTooltipCell>
              <ChartsTooltipCell className={clsx(classes.valueCell, classes.cell)}>
                <Typography>{'QoQ: ' + totalChangeQoq + '%, YoY: ' + totalChangeYoy + '%'}</Typography>
              </ChartsTooltipCell>
            </ChartsTooltipRow>
          </>
        }

      </ChartsTooltipTable>
    </ChartsTooltipPaper>
  );
}

TotalChartsAxisTooltipContent.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // | To update them edit the TypeScript types and run "pnpm proptypes"  |
  // ----------------------------------------------------------------------
  /**
   * The properties of the triggered axis.
   */
  axis: PropTypes.object.isRequired,
  /**
   * Data identifying the triggered axis.
   */
  axisData: PropTypes.shape({
    x: PropTypes.shape({
      index: PropTypes.number,
      value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string])
        .isRequired
    }),
    y: PropTypes.shape({
      index: PropTypes.number,
      value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string])
        .isRequired
    })
  }).isRequired,
  /**
   * The value associated to the current mouse position.
   */
  axisValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string]),
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The index of the data item triggered.
   */
  dataIndex: PropTypes.number,
  /**
   * The series linked to the triggered axis.
   */
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object
  ])
} as any;

export { TotalChartsAxisTooltipContent };
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  BarPlot,
  LinePlot,
  ResponsiveChartContainer,
  ChartsLegend,
  ChartsTooltip,
  ChartsAxisHighlight,
  ChartsAxis,
  ChartsReferenceLine
} from '@mui/x-charts';
import {
  Container,
  Card,
  Typography,
  ToggleButton, ToggleButtonGroup,
  Link, Box
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Footer from 'src/components/Footer';
import { CompanyContext } from '../../../contexts/CompanyContext';
import SuspenseLoader from '../../../components/SuspenseLoader';
import { useFetchWithAuth } from '../../../auth0/useFetchWithAuth';
import CompanyLogoPageTitle from '../../../components/CompanyLogoPageTitle';
import { TotalChartsAxisTooltipContent } from './TotalChartsAxisTooltipContent';
import NestedMenu from 'src/components/NestedMenu';
import {
  AttachMoney,
  AutoGraph,
  Balance, BatteryChargingFull,
  CurrencyExchange, DirectionsCar,
  DirectionsRun, Expand, Gavel, Lightbulb, MoneyOff, Nature, NextWeek, NoCrash, Payment,
  Percent, PieChart, RocketLaunch, Share, SsidChart, Straighten, TrendingUp, Troubleshoot,
  VerticalAlignBottom,
  VerticalAlignTop, VolunteerActivism, Work
} from '@mui/icons-material';
import CompareArrows from '@mui/icons-material/CompareArrows';
import BarChartIcon from '@mui/icons-material/BarChart';

interface Revenues {
  xaxisLabels: string[];
  xaxisLabelsCompanySpecific: string[];

  revenueTtmData: number[];
  costOfRevenueTtmData: number[];
  grossProfitTtmData: number[];

  netIncomeTtmData: number[];
  ebitTtmData: number[];
  ebitdaTtmData: number[];

  ocfTtmData: number[];
  capexTtmData: number[];
  fcfTtmData: number[];

  revenueData: number[];
  costOfRevenueData: number[];
  grossProfitData: number[];

  netIncomeData: number[];
  ebitData: number[];
  ebitdaData: number[];

  ocfData: number[];
  capexData: number[];
  fcfData: number[];

  assets: number[];
  cashAndCashEquivalents: number[];
  totalDebt: number[];

  roe: number[];
  roic: number[];
  cfroic: number[];

  numberOfShares: number[];

  epsPerQuarter: number[];
  epsTtmPerQuarter: number[];

  grossProfitMargin: number[];
  grossProfitMarginTtm: number[];

  netIncomeMargin: number[];
  netIncomeMarginTtm: number[];

  ebitdaMargin: number[];
  ebitdaMarginTtm: number[];

  fcfMargin: number[];
  fcfMarginTtm: number[];

  operatingMargin: number[];
  operatingMarginTtm: number[];

  equity: number[];
  equityTtm: number[];

  investedCapital: number[];

  opExData: number[];
  opExDataTtm: number[];

  sga: number[];
  sgaTtm: number[];

  rnd: number[];
  rndTtm: number[];

  sellingMarketing: number[];
  sellingMarketingTtm: number[];

  gna: number[];
  gnaTtm: number[];

  opExMargin: number[];
  opExMarginTtm: number[];

  capExMargin: number[];
  capExMarginTtm: number[];

  dividends: number[];
  dividendsTtm: number[];

  payoutRatio: number[];
  payoutRatioTtm: number[];

  epsGrowth: number[];
  epsGrowthTtm: number[];

  netCashOpExCapExTtm: number[];

  dividendYieldTtm: number[];

  revenueGrowthRateYoY: number[];
  ruleOf40: number[];

  deliveriesModel3y: number[];
  deliveriesModel3yTtm: number[];

  deliveriesOtherModels: number[];
  deliveriesOtherModelsTtm: number[];

  tslaTotalAutomotiveRevenue: number[];
  tslaTotalAutomotiveRevenueTtm: number[];

  tslaTotalAutomotiveCostOfRevenue: number[];
  tslaTotalAutomotiveCostOfRevenueTtm: number[];

  tslaTotalAutomotiveGrossProfit: number[];
  tslaTotalAutomotiveGrossProfitTtm: number[];

  energyStorageDeployed: number[];
  energyStorageDeployedTtm: number[];

  tslaEnergyGenerationAndStorageRevenue: number[];
  tslaEnergyGenerationAndStorageRevenueTtm: number[];
  tslaEnergyGenerationAndStorageCostOfRevenue: number[];
  tslaEnergyGenerationAndStorageCostOfRevenueTtm: number[];
  tslaEnergyGenerationAndStorageGrossProfit: number[];
  tslaEnergyGenerationAndStorageGrossProfitTtm: number[];

  tslaServicesAndOtherRevenue: number[];
  tslaServicesAndOtherRevenueTtm: number[];
  tslaServicesAndOtherCostOfRevenue: number[];
  tslaServicesAndOtherCostOfRevenueTtm: number[];
  tslaServicesAndOtherGrossProfit: number[];
  tslaServicesAndOtherGrossProfitTtm: number[];

  tslaTotalAutomotiveGrossProfitMargin: number[];
  tslaTotalAutomotiveGrossProfitMarginTtm: number[];
  tslaEnergyGenerationAndStorageGrossProfitMargin: number[];
  tslaEnergyGenerationAndStorageGrossProfitMarginTtm: number[];
  tslaServicesAndOtherGrossProfitMargin: number[];
  tslaServicesAndOtherGrossProfitMarginTtm: number[];

  tslaAverageVehicleSellingPrice: number[];

  tslaAutomotiveRegulatoryCredits: number[];
  tslaAutomotiveRegulatoryCreditsTtm: number[];
}

interface Prices {
  dates: string[];
  prices: string[];
}

interface Section {
  name: string;
  label?: string;
  icon?: React.ReactNode;
  unit: Unit;
  periodIndependent: boolean;
  convertToHigherUnit?: boolean;
  xAxisLabelsType?: XAxisLabelType;
}

interface Unit {
  prefix: string;
  suffix: string;
  higherSuffix?: string;
}

enum XAxisLabelType {
  COMPANY_SPECIFIC
}

const sections: Section[] = [
  {
    name: 'stockPrice', label: 'Stock Price', unit: { prefix: '$', suffix: '', higherSuffix: '' }, periodIndependent: true,
    icon: <TrendingUp fontSize="small" />
  },
  {
    name: 'topline', label: 'Top-Line', unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' }, periodIndependent: false,
    icon: <VerticalAlignTop fontSize="small" />
  },
  {
    name: 'profit',
    label: 'Profitability',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <VerticalAlignBottom fontSize="small" />
  },
  {
    name: 'margins', label: 'Margins', unit: { prefix: '', suffix: '%' }, periodIndependent: false,
    icon: <Percent fontSize="small" />
  },
  {
    name: 'cash', label: 'Cashflow', unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' }, periodIndependent: false,
    icon: <CurrencyExchange fontSize="small" />
  },
  {
    name: 'balanceSheet',
    label: 'Health',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: true,
    icon: <Balance fontSize="small" />
  },
  {
    name: 'performance', label: 'Performance', unit: { prefix: '', suffix: '%' }, periodIndependent: true,
    icon: <DirectionsRun fontSize="small" />
  },
  {
    name: 'eps', label: 'Earnings Per Share', unit: { prefix: '$', suffix: '' }, periodIndependent: false,
    icon: <Lightbulb fontSize="small" />
  },
  {
    name: 'shares',
    label: 'Number of Shares',
    unit: { prefix: '', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: true,
    icon: <Share fontSize="small" />
  },
  {
    name: 'equity', label: 'Equity', unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' }, periodIndependent: false,
    icon: <Nature fontSize="small" />
  },
  {
    name: 'investedCapital',
    label: 'Invested Capital',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: true,
    icon: <VolunteerActivism fontSize="small" />
  },
  {
    name: 'operationalLeverage',
    label: 'Operational Leverage',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <RocketLaunch fontSize="small" />
  },
  {
    name: 'opExVsGrossProfit',
    label: 'Operating Expenses vs. Gross Profit',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <CompareArrows fontSize="small" />
  },
  {
    name: 'opExDistribution',
    label: 'Operating Expense Distribution',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <PieChart fontSize="small" />
  },
  {
    name: 'opExVsCapEx',
    label: 'Operating Expenses vs. Capital Expenditures',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <SsidChart fontSize="small" />
  },
  {
    name: 'opExVsCapExMargin',
    label: 'OpEx vs. CapEx Margin',
    unit: { prefix: '', suffix: '%' },
    periodIndependent: false,
    icon: <Troubleshoot fontSize="small" />
  },
  {
    name: 'epsGrowth', label: 'EPS Growth', unit: { prefix: '', suffix: '%' }, periodIndependent: false,
    icon: <Expand fontSize="small" />
  },
  {
    name: 'netCashOpExCapEx',
    label: 'Net Cash / (OpEx TTM + CapEx TTM)',
    unit: { prefix: '', suffix: '' },
    periodIndependent: true,
    icon: <AutoGraph fontSize="small" />
  },
  {
    name: 'ruleOf40', label: 'Rule of 40', unit: { prefix: '', suffix: '%' }, periodIndependent: true,
    icon: <Straighten fontSize="small" />
  },
  {
    name: 'vehicleDeliveries',
    label: 'Vehicle Deliveries',
    unit: { prefix: '', suffix: '', higherSuffix: 'K' },
    periodIndependent: false,
    xAxisLabelsType: XAxisLabelType.COMPANY_SPECIFIC,
    icon: <NoCrash fontSize="small" />
  },
  {
    name: 'energyStorageDeployed',
    label: 'Energy Storage Deployed',
    unit: { prefix: '', suffix: 'MWhs', higherSuffix: 'GWhs' },
    periodIndependent: false,
    xAxisLabelsType: XAxisLabelType.COMPANY_SPECIFIC,
    icon: <BatteryChargingFull fontSize="small" />
  },
  {
    name: 'revenueBySegment',
    label: 'Revenue By Segment',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <BarChartIcon fontSize="small" />
  },
  {
    name: 'costOfRevenueBySegment',
    label: 'Cost of Revenue By Segment',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <MoneyOff fontSize="small" />
  },
  {
    name: 'grossProfitBySegment',
    label: 'Gross Profit By Segment',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <AttachMoney fontSize="small" />
  },
  {
    name: 'grossMarginsBySegment',
    label: 'Gross Profit Margins By Segment', unit: { prefix: '', suffix: '%' }, periodIndependent: false,
    icon: <Percent fontSize="small" />
  },
  {
    name: 'automotiveRegulatoryCredits',
    label: 'Automotive Regulatory Credit Sales',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <Gavel fontSize="small" />
  },
  {
    name: 'averageVehicleSellingPrice',
    label: 'Average Vehicle Selling Price', unit: { prefix: '$', suffix: '' }, periodIndependent: true,
    icon: <DirectionsCar fontSize="small" />
  },
  {
    name: 'dividends', label: 'Dividend Per Share', unit: { prefix: '$', suffix: '' }, periodIndependent: false,
    icon: <Work fontSize="small" />
  },
  {
    name: 'dividendYield', label: 'Dividend Yield', unit: { prefix: '', suffix: '%' }, periodIndependent: true,
    icon: <NextWeek fontSize="small" />
  },
  {
    name: 'payoutRatio', label: 'Payout Ratio', unit: { prefix: '', suffix: '%' }, periodIndependent: false,
    icon: <Payment fontSize="small" />
  },
];

function ChartsRevenue() {

  const { selectedCompany } = useContext(CompanyContext);
  const [revenues, setRevenues] = useState<Revenues | undefined>(undefined);
  const [prices, setPrices] = useState<Prices | undefined>(undefined);
  const [period, setPeriod] = useState('ttm');
  const [section, setSection] = useState(sections.find(section => section.name === 'topline'));
  const fetchWithAuth = useFetchWithAuth();

  const handlePeriodChange = (event: React.MouseEvent<HTMLElement>, newPeriod: string) => {
    if (newPeriod != null) {
      setPeriod(newPeriod);
    }
    console.log(event, newPeriod);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchWithAuth<Revenues>('/api/revenues?ticker=' + selectedCompany.ticker);
      setRevenues(response);
      setSection(sections.find(section => section.name === 'topline'));
    };
    fetchData().then();
  }, [selectedCompany.ticker]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchWithAuth<Prices>('/api/prices?ticker=' + selectedCompany.ticker);
      setPrices(response);
    };
    fetchData().then();
  }, [selectedCompany.ticker]);

  function isTouchDevice() {
    return ('ontouchstart' in window || navigator.maxTouchPoints > 0
      || window.matchMedia('(pointer: coarse)').matches);
  }

  if (!revenues) {
    return (<SuspenseLoader />);
  }

  function switchResult(section: Section) {
    switch (section.name) {
      default:
        return [];
      case 'stockPrice':
        return [{
          type: 'line',
          label: 'Stock Price',
          data: prices.prices
        }];
      case 'topline':
        section.convertToHigherUnit = Math.max(...revenues.revenueData) > 1000;
        return [{
          label: 'Gross Profit' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.grossProfitTtmData : revenues.grossProfitData.slice(3)
        }, {
          label: 'Cost of Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.costOfRevenueTtmData : revenues.costOfRevenueData.slice(3)
        }, {
          label: 'Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.revenueTtmData : revenues.revenueData.slice(3)
        }];
      case 'profit':
        section.convertToHigherUnit = Math.max(...revenues.ebitdaData) > 1000;
        return [{
          label: 'Net Income' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.netIncomeTtmData : revenues.netIncomeData.slice(3)
        }, {
          label: 'EBIT' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.ebitTtmData : revenues.ebitData.slice(3)
        }, {
          label: 'EBITDA' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.ebitdaTtmData : revenues.ebitdaData.slice(3)
        }];
      case 'margins':
        return [{
          type: 'line',
          label: 'Gross Profit Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.grossProfitMarginTtm : revenues.grossProfitMargin.slice(3)
        }, {
          type: 'line',
          label: 'EBITDA Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.ebitdaMarginTtm : revenues.ebitdaMargin.slice(3)
        }, {
          type: 'line',
          label: 'Operating Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.operatingMarginTtm : revenues.operatingMargin.slice(3)
        }, {
          type: 'line',
          label: 'Net Income Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.netIncomeMarginTtm : revenues.netIncomeMargin.slice(3)
        }, {
          type: 'line',
          label: 'FCF Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.fcfMarginTtm : revenues.fcfMargin.slice(3)
        }];
      case 'cash':
        section.convertToHigherUnit = Math.max(...revenues.ocfData) > 1000;
        return [{
          label: 'Operating Cashflow' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.ocfTtmData : revenues.ocfData.slice(3)
        }, {
          label: 'Capital Expenditures' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.capexTtmData : revenues.capexData.slice(3)
        }, {
          label: 'Free Cashflow' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.fcfTtmData : revenues.fcfData.slice(3)
        }];
      case 'balanceSheet':
        section.convertToHigherUnit = Math.max(...revenues.assets) > 1000;
        return [{
          label: 'Total Debt',
          data: revenues.totalDebt.slice(3)
        }, {
          label: 'Cash And Cash Equivalents',
          data: revenues.cashAndCashEquivalents.slice(3)
        }, {
          label: 'Assets',
          data: revenues.assets.slice(3)
        }];
      case 'performance':
        return [{
          type: 'line',
          label: 'ROE',
          data: revenues.roe.slice(3)
        }, {
          type: 'line',
          label: 'ROIC',
          data: revenues.roic.slice(3)
        }, {
          type: 'line',
          label: 'CFROIC',
          data: revenues.cfroic
        }];
      case 'eps':
        return [{
          label: 'EPS' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.epsTtmPerQuarter : revenues.epsPerQuarter.slice(3)
        }];
      case 'shares':
        section.convertToHigherUnit = Math.max(...revenues.numberOfShares) > 1000;
        return [{
          label: 'Shares Outstanding',
          data: revenues.numberOfShares.slice(3)
        }];
      case 'equity':
        section.convertToHigherUnit = Math.max(...revenues.equity) > 1000;
        return [{
          label: 'Equity' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.equityTtm.slice(3) : revenues.equity.slice(3)
        }];
      case 'investedCapital':
        section.convertToHigherUnit = Math.max(...revenues.investedCapital) > 1000;
        return [{
          label: 'Invested Capital',
          data: revenues.investedCapital.slice(3)
        }];
      case 'operationalLeverage':
        section.convertToHigherUnit = Math.max(...revenues.revenueData) > 1000;
        return [{
          type: 'line',
          label: 'Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.revenueTtmData : revenues.revenueData.slice(3)
        }, {
          type: 'line',
          label: 'Capital Expenditures' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.capexTtmData : revenues.capexData.slice(3)
        }, {
          type: 'line',
          label: 'Operating Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.opExDataTtm : revenues.opExData.slice(3)
        }];
      case 'opExVsGrossProfit':
        section.convertToHigherUnit = Math.max(...revenues.grossProfitData) > 1000;
        return [{
          label: 'Gross Profit' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.grossProfitTtmData : revenues.grossProfitData.slice(3)
        }, {
          label: 'Operating Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.opExDataTtm : revenues.opExData.slice(3)
        }, {
          label: 'Selling General and Administrative' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.sgaTtm : revenues.sga.slice(3)
        }, {
          label: 'Research & Development' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.rndTtm : revenues.rnd.slice(3)
        }];
      case 'opExDistribution':
        section.convertToHigherUnit = Math.max(...revenues.opExData) > 1000;
        return [{
          label: 'Operating Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.opExDataTtm : revenues.opExData.slice(3)
        }, {
          label: 'Selling General and Administrative' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.sgaTtm : revenues.sga.slice(3)
        }, {
          label: 'Research & Development' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.rndTtm : revenues.rnd.slice(3)
        }, {
          label: 'Selling & Marketing Expense TTM' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.sellingMarketingTtm : revenues.sellingMarketing.slice(3)
        }, {
          label: 'General & Administrative Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.gnaTtm : revenues.gna.slice(3)
        }];
      case 'opExVsCapEx':
        section.convertToHigherUnit = Math.max(...revenues.opExData) > 1000;
        return [{
          type: 'line',
          label: 'Operating Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.opExDataTtm : revenues.opExData.slice(3)
        }, {
          type: 'line',
          label: 'Capital Expenditures' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.capexTtmData : revenues.capexData.slice(3)
        }];
      case 'opExVsCapExMargin':
        return [{
          type: 'line',
          label: 'Operating Expense Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.opExMarginTtm : revenues.opExMargin.slice(3)
        }, {
          type: 'line',
          label: 'Capital Expenditures Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.capExMarginTtm : revenues.capExMargin.slice(3)
        }];
      case 'dividends':
        return [{
          label: 'Dividends' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.dividendsTtm : revenues.dividends.slice(3)
        }];
      case 'payoutRatio':
        return [{
          label: 'Payout Ratio' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.payoutRatioTtm : revenues.payoutRatio.slice(3)
        }];
      case 'epsGrowth':
        return [{
          type: 'line',
          label: 'EPS Growth' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.epsGrowthTtm : revenues.epsGrowth.slice(3)
        }];
      case 'netCashOpExCapEx':
        return [{
          type: 'line',
          label: 'Net Cash / OpEx + CapEx TTM',
          data: revenues.netCashOpExCapExTtm
        }];
      case 'dividendYield':
        return [{
          type: 'line',
          label: 'Dividend Yield TTM',
          data: revenues.dividendYieldTtm
        }];
      case 'ruleOf40':
        return [{
          type: 'line',
          label: 'Revenue Growth Rate YoY',
          data: revenues.revenueGrowthRateYoY.slice(revenues.revenueGrowthRateYoY.length - 16)
        }, {
          type: 'line',
          label: 'FCF Margin',
          data: revenues.fcfMargin.slice(revenues.fcfMargin.length - 16)
        }, {
          type: 'line',
          label: 'Rule of 40',
          data: revenues.ruleOf40.slice(revenues.ruleOf40.length - 16)
        }];
      case 'vehicleDeliveries':
        section.convertToHigherUnit = Math.max(...revenues.deliveriesModel3y) > 1000;
        return [{
          label: 'Model 3 and Y' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.deliveriesModel3yTtm : revenues.deliveriesModel3y.slice(3),
          stack: 'vehicles'
        }, {
          label: 'Model S, X and Cybertruck' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.deliveriesOtherModelsTtm : revenues.deliveriesOtherModels.slice(3),
          stack: 'vehicles'
        }];
      case 'energyStorageDeployed':
        section.convertToHigherUnit = Math.max(...revenues.energyStorageDeployed) > 1000;
        return [{
          label: 'Energy Storage Deployed' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.energyStorageDeployedTtm : revenues.energyStorageDeployed.slice(3)
        }];
      case 'revenueBySegment':
        section.convertToHigherUnit = Math.max(...revenues.tslaTotalAutomotiveRevenue) > 1000;
        return [{
          label: 'Total Automotive Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.tslaTotalAutomotiveRevenueTtm : revenues.tslaTotalAutomotiveRevenue.slice(3),
          stack: 'revenue'
        }, {
          label: 'Energy Generation and Storage Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.tslaEnergyGenerationAndStorageRevenueTtm : revenues.tslaEnergyGenerationAndStorageRevenue.slice(3),
          stack: 'revenue'
        }, {
          label: 'Services and Other Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.tslaServicesAndOtherRevenueTtm : revenues.tslaServicesAndOtherRevenue.slice(3),
          stack: 'revenue'
        }];
      case 'costOfRevenueBySegment':
        section.convertToHigherUnit = Math.max(...revenues.tslaTotalAutomotiveCostOfRevenue) > 1000;
        return [{
          label: 'Total Automotive Cost of Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.tslaTotalAutomotiveCostOfRevenueTtm : revenues.tslaTotalAutomotiveCostOfRevenue.slice(3),
          stack: 'costOfRevenue'
        }, {
          label: 'Energy Generation and Storage Cost of Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.tslaEnergyGenerationAndStorageCostOfRevenueTtm : revenues.tslaEnergyGenerationAndStorageCostOfRevenue.slice(3),
          stack: 'costOfRevenue'
        }, {
          label: 'Services and Other Cost of Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.tslaServicesAndOtherCostOfRevenueTtm : revenues.tslaServicesAndOtherCostOfRevenue.slice(3),
          stack: 'costOfRevenue'
        }];
      case 'grossProfitBySegment':
        section.convertToHigherUnit = Math.max(...revenues.tslaTotalAutomotiveGrossProfit) > 1000;
        return [{
          label: 'Total Automotive Gross Profit' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.tslaTotalAutomotiveGrossProfitTtm : revenues.tslaTotalAutomotiveGrossProfit.slice(3),
          stack: 'grossProfit'
        }, {
          label: 'Energy Generation and Storage Gross Profit' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.tslaEnergyGenerationAndStorageGrossProfitTtm : revenues.tslaEnergyGenerationAndStorageGrossProfit.slice(3),
          stack: 'grossProfit'
        }, {
          label: 'Services and Other Gross Profit' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.tslaServicesAndOtherGrossProfitTtm : revenues.tslaServicesAndOtherGrossProfit.slice(3),
          stack: 'grossProfit'
        }];
      case 'grossMarginsBySegment':
        return [{
          type: 'line',
          label: 'Total Automotive Gross Profit Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.tslaTotalAutomotiveGrossProfitMarginTtm: revenues.tslaTotalAutomotiveGrossProfitMargin.slice(3)
        }, {
          type: 'line',
          label: 'Energy Generation and Storage Gross Profit Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.tslaEnergyGenerationAndStorageGrossProfitMarginTtm : revenues.tslaEnergyGenerationAndStorageGrossProfitMargin.slice(3)
        }, {
          type: 'line',
          label: 'Services and Other Gross Profit Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.tslaServicesAndOtherGrossProfitMarginTtm : revenues.tslaServicesAndOtherGrossProfitMargin.slice(3)
        }];
      case 'averageVehicleSellingPrice':
        return [{
          type: 'line',
          label: 'Average Vehicle Selling Price',
          data: revenues.tslaAverageVehicleSellingPrice.slice(revenues.tslaAverageVehicleSellingPrice.length - revenues.xaxisLabels.length)
        }];
      case 'automotiveRegulatoryCredits':
        return [{
          label: 'Automotive Regulatory Credit Sales' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.tslaAutomotiveRegulatoryCreditsTtm : revenues.tslaAutomotiveRegulatoryCredits.slice(3)
        }];
    }
  }

  const series = switchResult(section);

  const myColorPalette = [
    '#E15759', // Red
    '#F28E2C', // Orange
    '#4E79A7', // Blue
    '#76B7B2', // Teal
    '#59A14F', // Green
    '#EDC948', // Yellow
    '#AF7AA1', // Purple
    '#FF9DA7', // Pink
    '#9C755F', // Brown
    '#BAB0AC'  // Gray
  ];

  return (
    <>
      <Helmet>
        <title>Charts</title>
      </Helmet>

      <CompanyLogoPageTitle />

      <Container maxWidth="xl">
        <Grid container>
          <Grid size={{ xs: 12 }}>
            <Card>

              <Grid justifyContent="space-between" display={'flex'}>

                <Box alignItems="left" sx={{ marginTop: 1, marginLeft: 1 }}>
                  <NestedMenu onClick={
                    (newSection: string) => setSection(sections.find(section => section.name === newSection))}
                              showKpis={revenues.deliveriesModel3y.length > 0 && revenues.energyStorageDeployed.length > 0}
                              showDividends={revenues.dividends.length > 0}
                  />
                </Box>

                <Box alignItems="center" sx={{
                  marginTop: 2, display: {
                    xs: 'none',
                    sm: 'block'
                  }
                }}>
                  <Box display={'flex'}>
                    {section.icon}&nbsp;<Typography variant={'h4'}
                                                    sx={{ mt: 0.3 }}>{section.label ? section.label : section.name}</Typography>
                  </Box>
                </Box>

                <Box alignItems="right" sx={{ marginTop: 1, marginRight: 1 }}>
                  <ToggleButtonGroup
                    value={section.periodIndependent ? null : period}
                    exclusive
                    size={'small'}
                    fullWidth={false}
                    onChange={handlePeriodChange}
                    disabled={section.periodIndependent}>
                    <ToggleButton value="quarterly">Quarterly</ToggleButton>
                    <ToggleButton value="ttm">TTM</ToggleButton>
                  </ToggleButtonGroup>
                </Box>

              </Grid>

              <Grid container height={'68vh'}>

                {section.name == 'shares' &&
                  <Typography variant="h4" sx={{ ml: 'auto', mr: 'auto', pt: 2, pb: 1 }}>
                    Ideally no increase in past 10 years, stays the same or deceases.
                  </Typography>
                }
                {section.name == 'netCashOpExCapEx' &&
                  <Typography variant="h4" sx={{ ml: 8, mr: 8, pt: 1, pb: 0 }}>
                    Net Cash / (OpEx TTM + CapEx TTM) represents a financial ratio that provides insight into a
                    company's financial health and operational efficiency. A higher ratio suggests the company is in a
                    stronger balance sheet and has better operational efficiency. It's best to compare this ratio
                    against other companies (<Link href="https://x.com/freshjiva/status/1583093721721368576"
                                                   target="_blank" rel="noopener noreferrer"
                                                   color="primary">see Mayur Thaker's post</Link>).
                  </Typography>
                }
                {section.name == 'ruleOf40' &&
                  <Typography variant="h4" sx={{ ml: 8, mr: 8, pt: 1, pb: 0 }}>
                    The Rule of 40 is a guideline often used in the technology and software-as-a-service (SaaS) sectors
                    to evaluate the performance and growth potential of a company. It balances revenue growth and
                    profitability to provide a simple metric for investors and management to assess whether the company
                    is on a healthy growth trajectory (<Link
                    href="https://chatgpt.com/share/ae4e4d14-59d0-4162-9a35-2a5dc8853298"
                    target="_blank" rel="noopener noreferrer"
                    color="primary">see Rule of 40 Overview</Link>).
                  </Typography>
                }
                {section.name != 'ruleOf40' && section.name != 'netCashOpExCapEx' && section.name != 'shares' &&
                  <Typography variant="h4" sx={{ ml: 8, mr: 8, pt: 1, pb: 0 }} height={30}>&nbsp;</Typography>
                }

                {section.name != 'stockPrice' &&
                  <ResponsiveChartContainer
                    xAxis={[{
                      scaleType: 'band',
                      data: section.name == 'ruleOf40' ? revenues.xaxisLabels.slice(revenues.xaxisLabels.length - 16)
                        : section.xAxisLabelsType == XAxisLabelType.COMPANY_SPECIFIC ?
                          revenues.xaxisLabelsCompanySpecific : revenues.xaxisLabels,
                      valueFormatter: (value) => value.slice(0, 2) + '\n' + value.slice(2)
                    }]}
                    yAxis={[{
                      label: section.name == 'energyStorageDeployed' ? section.convertToHigherUnit ? 'in GWhs' : 'in MWhs' : '',
                      valueFormatter: (value: number) => {
                        return section.unit.prefix + (section.convertToHigherUnit ? value / 1000 : value) +
                          (section.name == 'energyStorageDeployed' ? '' :
                            section.convertToHigherUnit ? section.unit.higherSuffix : section.unit.suffix);
                      }
                    }]}
                    colors={myColorPalette}
                    series={series
                      .map(s => {
                        return ({
                          ...s,
                          type: s.type ? s.type : 'bar',
                          data: s.data,
                          highlightScope: { highlighted: 'series', faded: 'global' },
                          valueFormatter: (value: number) => {
                            return section.unit.prefix + value + section.unit.suffix;
                          }
                        });
                      })}
                    sx={{ ml: 0, pl: 1, mr: -3, pr: 0, pb: 0, mb: 0 }}
                  >
                    <BarPlot />
                    <LinePlot />
                    <ChartsAxis />
                    <ChartsLegend />
                    <ChartsTooltip
                      slots={{
                        axisContent: TotalChartsAxisTooltipContent
                      }}
                      slotProps={{
                        axisContent: {
                          sx: { border: 'solid', borderWidth: 2, borderColor: 'divider' }
                        },
                        popper: {
                          placement: isTouchDevice() ? 'top' : 'auto-start',
                          modifiers: isTouchDevice() ? [{
                            name: 'offset',
                            options: {
                              offset: [0, 40] // [horizontal offset, vertical offset]
                            }
                          }] : []
                        }
                      }}
                    />
                    <ChartsAxisHighlight x={'band'} />
                    {section.name == 'ruleOf40' &&
                      <ChartsReferenceLine y={40}
                                           label="Rule of 40 Minimum"
                                           labelAlign={'start'}
                                           lineStyle={{
                                             strokeDasharray: '5 5'
                                           }} />
                    }
                  </ResponsiveChartContainer>}

                {section.name == 'stockPrice' &&
                  <ResponsiveChartContainer
                    xAxis={[{
                      scaleType: 'band',
                      data: prices.dates,
                      valueFormatter: (value, context) => {
                        switch (context.location) {
                          case 'legend':
                          case 'tick':
                            return new Date(value).getFullYear().toString(); // Format as year
                          case 'tooltip':
                            return value;
                        }
                      },
                      tickInterval: (_v, index) => {
                        const currentDate = new Date(prices.dates[index]);
                        const previousDate = index > 0 ? new Date(prices.dates[index - 1]) : null;
                        // Show the label if the current date is in a new year compared to the previous date
                        return !previousDate || currentDate.getFullYear() !== previousDate.getFullYear();
                      },
                      tickPlacement: 'start'
                    }]}
                    yAxis={[{
                      valueFormatter: (value: number) => {
                        return section.unit.prefix + value + section.unit.suffix;
                      }
                    }]}
                    colors={myColorPalette}
                    series={series
                      .map(s => {
                        return ({
                          ...s,
                          type: s.type ? s.type : 'bar',
                          data: s.data,
                          highlightScope: { highlighted: 'series', faded: 'global' },
                          valueFormatter: (value: number) => {
                            return section.unit.prefix + value + section.unit.suffix;
                          }
                        });
                      })}
                    sx={{ ml: 0, pl: 1, mr: -3, pr: 0, pb: 0, mb: 0 }}
                  >
                    <BarPlot />
                    <LinePlot />
                    <ChartsAxis />
                    <ChartsLegend />
                    <ChartsTooltip
                      slotProps={{
                        axisContent: {
                          sx: { border: 'solid', borderWidth: 2, borderColor: 'divider' }
                        },
                        popper: {
                          placement: isTouchDevice() ? 'top' : 'auto-start',
                          modifiers: isTouchDevice() ? [{
                            name: 'offset',
                            options: {
                              offset: [0, 40] // [horizontal offset, vertical offset]
                            }
                          }] : []
                        }
                      }}
                    />
                    <ChartsAxisHighlight x={'band'} />
                  </ResponsiveChartContainer>}

              </Grid>
              <Grid container height={section.name == 'netCashOpExCapEx' || section.name == 'ruleOf40' ? 90 : 70} />
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
}

export default ChartsRevenue;

import * as React from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {
  Assessment,
  AttachMoney,
  AutoGraph,
  Balance,
  BatteryChargingFull, Business,
  CurrencyExchange, DirectionsCar,
  DirectionsRun,
  Expand,
  Factory, Gavel, KeyboardReturn,
  Lightbulb,
  MonetizationOn, MoneyOff,
  MonitorHeart,
  Nature, NextWeek,
  NoCrash, Payment,
  Percent,
  PieChart,
  RocketLaunch,
  Share,
  SsidChart, Straighten, TrendingUp, Troubleshoot,
  VerticalAlignBottom,
  VerticalAlignTop, VolunteerActivism,
  Work
} from '@mui/icons-material';
import CompareArrows from '@mui/icons-material/CompareArrows';
import { useRef, useState } from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';

// This function checks whether a point (x, y) is on the left or right side of a line formed by two points (px, py) and (qx, qy).
// If the result is negative, the point is on the right side of the line. If positive, it's on the left side.
// It helps us determine if a point is on the same side as a vertex of the triangle when compared to its edges.
function sign(
  px: number,
  py: number,
  qx: number,
  qy: number,
  rx: number,
  ry: number
) {
  return (px - rx) * (qy - ry) - (qx - rx) * (py - ry);
}

// This function checks if a point (x, y) is inside a triangle formed by three points (x1, y1), (x2, y2), and (x3, y3).
function pointInTriangle(
  currentMouseCoordinates: Array<number>,
  triangleCoordinates: Array<Array<number>>
) {
  const [[x1, y1], [x2, y2], [x3, y3]] = triangleCoordinates;
  const [x, y] = currentMouseCoordinates;

  const b1 = sign(x, y, x1, y1, x2, y2) <= 0;
  const b2 = sign(x, y, x2, y2, x3, y3) <= 0;
  const b3 = sign(x, y, x3, y3, x1, y1) <= 0;
  // If all signs are the same (either all negative or all positive), the point is inside the triangle.
  return b1 === b2 && b2 === b3;
}

type Option = {
  label: string;
  value?: string;
  hide?: boolean;
  menuLevel: number;
  nestedOptions?: Array<Option>;
  icon?: React.ReactNode;
};

type SubMenuProps = {
  menuLevels: number;
  options: Array<Option>;
  onOptionClick: (option: Option) => void;
};

function SubMenu({ options, menuLevels, onOptionClick }: SubMenuProps) {
  const [anchors, setAnchors] = useState<{
    elements: Array<null | HTMLElement>;
    options: Array<null | typeof options>;
  }>({
    elements: new Array(menuLevels).fill(null),
    options: new Array(menuLevels).fill(null)
  });
  const [isTouch, setIsTouch] = useState(false);

  const mouseEntered = useRef<Record<string, boolean>>({});
  const mouseLeftCoordinates = useRef<Array<number>>([]);
  const buttonRef = useRef(null);
  const mouseIdleTimer = useRef<NodeJS.Timeout | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement> |
    React.TouchEvent<HTMLLIElement>, level = 0, nestedOptions = options) => {

    const target = event.currentTarget;

    setAnchors((prevAnchors) => ({
      elements: prevAnchors.elements.map((element, idx) =>
        idx === level ? target : element
      ),
      options: prevAnchors.options.map((opts, idx) =>
        idx === level ? nestedOptions || null : opts
      )
    }));
  };

  const handleClose = (level: number) => {
    setAnchors((prevAnchors) => ({
      elements: prevAnchors.elements.map((element, index) =>
        index >= level ? null : element
      ),
      options: prevAnchors.options.map((element, index) =>
        index >= level ? null : element
      )
    }));
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (event.target === buttonRef.current) {
      handleClose(0);
      return;
    }

    const optionWithoutSubMenu = anchors.elements.every(
      (element) => !event.composedPath().includes(element!)
    );

    if (optionWithoutSubMenu) {
      handleClose(0);
    }
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLLIElement>, option: Option) => {
    setIsTouch(true); // Mark interaction as touch
    handleSelectOption(event, option);
  };

  const handleSelectOption = (event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> |
    React.TouchEvent<HTMLLIElement>, option: Option) => {

    if (!option.nestedOptions) {
      onOptionClick(option);
      handleClose(0);
    } else {
      handleClose(option.menuLevel + 1);
      handleOpen(event, option.menuLevel + 1, option.nestedOptions);
    }
  };

  const handleMouseMove = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: Option,
    optIndex: number
  ) => {
    if (isTouch) return; // Ignore mouse events if interaction is touch
    let shouldComputeSubMenuOpenLogic = true;
    const submenu = document.querySelector(`#nested-menu-${option.menuLevel + 1}`);

    function computeSubMenuLogic() {
      if (!mouseEntered.current[getId(option, optIndex)]) {
        mouseEntered.current[getId(option, optIndex)] = true;
        // Close all prior submenus if the mouse transitions from an option with a submenu to an option without a submenu.
        if (!option.nestedOptions) {
          handleClose(option.menuLevel + 1);
        } else if (
          // If the mouse moves from an option with a submenu to another option with a submenu, open the submenu of the current option and close the submenu of the previous option.
          option.nestedOptions &&
          anchors.options[option.menuLevel + 1] &&
          !option.nestedOptions.every(
            (val, i) =>
              val.label === anchors.options[option.menuLevel + 1]?.[i].label
          )
        ) {
          handleClose(option.menuLevel + 1);
          handleOpen(event, option.menuLevel + 1, option.nestedOptions);
        } else {
          handleOpen(event, option.menuLevel + 1, option.nestedOptions);
        }
      }
    }

    if (mouseLeftCoordinates.current.length > 0 && submenu) {
      const { x, y, height } = submenu.getBoundingClientRect();

      // Form a virtual triangle using the left mouse coordinates and the top-left and bottom-left coordinates of the submenu. If the current mouse coordinates fall within this triangle, skip the submenu logic computation.
      // Check https://twitter.com/diegohaz/status/1283558204178407427 for more context.
      const currentMouseCoordinates = [event.clientX, -event.clientY];
      const virtualTriangleCoordinates = [
        [x, -y],
        [x, -(y + height)],
        [mouseLeftCoordinates.current[0], mouseLeftCoordinates.current[1]]
      ];

      if (pointInTriangle(currentMouseCoordinates, virtualTriangleCoordinates)) {
        shouldComputeSubMenuOpenLogic = false;
        if (mouseIdleTimer.current) {
          clearTimeout(mouseIdleTimer.current);
        }

        // if mouse is inside triangle and yet hasn't moved, we need to compute submenu logic after a delay
        mouseIdleTimer.current = setTimeout(() => {
          computeSubMenuLogic();
        }, 50);
      } else {
        shouldComputeSubMenuOpenLogic = true;
      }
    }

    if (shouldComputeSubMenuOpenLogic) {
      if (mouseIdleTimer.current) {
        clearTimeout(mouseIdleTimer.current);
      }
      computeSubMenuLogic();
    }
  };

  const handleMouseLeave = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: Option,
    optIndex: number
  ) => {
    if (isTouch) return; // Ignore mouse events if interaction is touch
    mouseLeftCoordinates.current = [event.clientX, -event.clientY];

    if (mouseIdleTimer.current) {
      clearInterval(mouseIdleTimer.current);
    }
    mouseEntered.current[getId(option, optIndex)] = false;
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLLIElement>,
    option: Option
  ) => {
    if (option.nestedOptions) {
      if (event.key === 'ArrowRight' || event.key === 'Enter') {
        handleOpen(event, option.menuLevel + 1, option.nestedOptions);
      }
    }
    if (event.key === 'ArrowLeft' && option.menuLevel > 0) {
      handleClose(option.menuLevel);
      anchors.elements[option.menuLevel]?.focus();
    }

    if (event.key === 'Escape') {
      handleClose(0);
    }
  };

  const getId = (option: (typeof options)[0], index: number) => {
    return `${index}-${option.menuLevel}`;
  };

  return (
    <>
      <Button
        ref={buttonRef}
        onClick={(event) => {
          handleOpen(event);
        }}>
        Select Chart <ChevronRight />
      </Button>

      {anchors.elements.map((anchorElement, index) =>
        anchorElement ? (
          <Popper
            open={Boolean(anchorElement)}
            anchorEl={anchorElement}
            key={`${anchorElement.innerText} menu`}
            placement={'right-start'}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 8] // Adjust offset to align submenus properly
                }
              },
              {
                name: 'preventOverflow',
                options: {
                  boundary: 'viewport',
                  padding: 8 // Ensure submenus don't overflow outside the viewport
                }
              }
            ]}
            transition>
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: 'left top' }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <MenuList
                      autoFocusItem={Boolean(anchorElement)}
                      id={`nested-menu-${index}`}
                    >
                      {(anchors.options[index] ?? []).filter((option) => !option.hide).map((option, optIndex) => (
                        <MenuItem
                          key={option.label}
                          sx={{ pl: 0.5, pr: option.nestedOptions ? 0.5 : 1.6, justifyContent: 'space-between' }}
                          onClick={(event) => handleSelectOption(event, option)}
                          onTouchStart={(event) => handleTouchStart(event, option)}
                          onMouseMove={(event) => handleMouseMove(event, option, optIndex)}
                          onMouseLeave={(event) => handleMouseLeave(event, option, optIndex)}
                          onKeyDown={(event) => handleKeyDown(event, option)}
                        >
                          <Box display={'flex'}>
                            {option.icon}&nbsp;&nbsp;{option.label}
                          </Box>
                          {option.nestedOptions ? (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <ChevronRight fontSize="small" />
                            </>
                          ) : null}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        ) : null
      )}
    </>
  );
}

// see https://deploy-preview-37570--material-ui.netlify.app/material-ui/react-menu/#nested-menu
// alternative: https://github.com/mui/material-ui/issues/11723#issuecomment-2261341148
interface NestedMenuProps {
  onClick?: (value: string) => void;
  showKpis: boolean;
  showDividends: boolean;
}

const NestedMenu: React.FC<NestedMenuProps> = ({ onClick, showKpis, showDividends }) => {

  const handleClickOption = (option: Option) => {
    if (option.value != undefined && option.value != '') {
      onClick(option.value);
    }
  };

  const options = [
    {
      label: 'Price Metrics',
      menuLevel: 0,
      icon: <Assessment fontSize="small" />,
      nestedOptions: [
        {
          label: 'Stock Price',
          value: 'stockPrice',
          icon: <TrendingUp fontSize="small" />,
          menuLevel: 1
        }
      ]
    },
    {
      label: 'Financial Health',
      menuLevel: 0,
      icon: <MonitorHeart fontSize="small" />,
      nestedOptions: [
        {
          label: 'Health',
          value: 'balanceSheet',
          icon: <Balance fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Equity',
          value: 'equity',
          icon: <Nature fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Number of Shares',
          value: 'shares',
          icon: <Share fontSize="small" />,
          menuLevel: 0
        }
      ]
    },
    {
      label: 'Profit',
      menuLevel: 0,
      icon: <MonetizationOn fontSize="small" />,
      nestedOptions: [
        {
          label: 'Top-Line',
          value: 'topline',
          icon: <VerticalAlignTop fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Bottom-Line',
          value: 'profit',
          icon: <VerticalAlignBottom fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Margins',
          value: 'margins',
          icon: <Percent fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Cashflow',
          value: 'cash',
          icon: <CurrencyExchange fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'EPS',
          value: 'eps',
          icon: <Lightbulb fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'EPS Growth',
          value: 'epsGrowth',
          icon: <Expand fontSize="small" />,
          menuLevel: 1
        }
      ]
    },
    {
      label: 'Operational',
      menuLevel: 0,
      icon: <Factory fontSize="small" />,
      nestedOptions: [
        {
          label: 'Operational Leverage',
          value: 'operationalLeverage',
          icon: <RocketLaunch fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'OpEx vs. Gross Profit',
          value: 'opExVsGrossProfit',
          icon: <CompareArrows fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'OpEx Distribution',
          value: 'opExDistribution',
          icon: <PieChart fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'OpEx vs. CapEx',
          value: 'opExVsCapEx',
          icon: <SsidChart fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'OpEx vs. CapEx Margin',
          value: 'opExVsCapExMargin',
          icon: <Troubleshoot fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Net Cash / OpEx + CapEx',
          value: 'netCashOpExCapEx',
          icon: <AutoGraph fontSize="small" />,
          menuLevel: 1
        }
      ]
    },
    {
      label: 'Performance',
      menuLevel: 0,
      icon: <DirectionsRun fontSize="small" />,
      nestedOptions: [
        {
          label: 'ROE, ROIC, CFROIC',
          value: 'performance',
          icon: <KeyboardReturn fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Invested Capital',
          value: 'investedCapital',
          icon: <VolunteerActivism fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Rule of 40',
          value: 'ruleOf40',
          icon: <Straighten fontSize="small" />,
          menuLevel: 1
        }
      ]
    },
    {
      label: 'Business',
      menuLevel: 0,
      icon: <Business fontSize="small" />,
      hide: !showKpis,
      nestedOptions: [
        {
          label: 'Vehicle Deliveries',
          value: 'vehicleDeliveries',
          icon: <NoCrash fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Average Vehicle Selling Price',
          value: 'averageVehicleSellingPrice',
          icon: <DirectionsCar fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Automotive Regulatory Credit Sales',
          value: 'automotiveRegulatoryCredits',
          icon: <Gavel fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Energy Storage Deployed',
          value: 'energyStorageDeployed',
          icon: <BatteryChargingFull fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Revenue By Segment',
          value: 'revenueBySegment',
          icon: <BarChartIcon fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Cost of Revenue By Segment',
          value: 'costOfRevenueBySegment',
          icon: <MoneyOff fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Gross Profit By Segment',
          value: 'grossProfitBySegment',
          icon: <AttachMoney fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Gross Profit Margins By Segment',
          value: 'grossMarginsBySegment',
          icon: <Percent fontSize="small" />,
          menuLevel: 1
        }
      ]
    },
    {
      label: 'Dividends',
      menuLevel: 0,
      icon: <Work fontSize="small" />,
      hide: !showDividends,
      nestedOptions: [
        {
          label: 'Dividends',
          value: 'dividends',
          icon: <Work fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Dividend Yield',
          value: 'dividendYield',
          icon: <NextWeek fontSize="small" />,
          menuLevel: 1
        },
        {
          label: 'Payout Ratio',
          value: 'payoutRatio',
          icon: <Payment fontSize="small" />,
          menuLevel: 1
        }
      ]
    }
  ];

  return (
    <SubMenu options={options} menuLevels={2} onOptionClick={handleClickOption} />
  );
};

export default NestedMenu;